/* You can add global styles to this file, and also import other style files */
.text-theme{
  /*  color: #b90017
 !important;*/
}
.bg-theme{
    background-color: #b90017 !important;
}
.btn-theme{
    background: #b90017;
    border-color: #b90017;
}
h2.td-color.right {
    margin-left: 100%;
    /* margin-top: 10px; */
}
.removecart {
    margin-left: 80%;
}

.product-box-related {
    height: 400px;
}

.growing {
	padding    : 50px 0;
	position   : relative;
	background : #f8f8f8;

	.section-heading {
		h1 {
			font-weight   : 500;
			margin-bottom : 50px;
		}
	}

	.growing-squre {
		border-top                 : 1px solid #C7C9CE;
		border-bottom-right-radius : 20px;
		border-bottom-left-radius  : 20px;
		overflow                   : hidden;
		border-radius: 6px;
		background-color           : #FFFFFF;
		//box-shadow                 : 0 2px 3px rgb(0 0 0 / 20%);
		box-shadow                 :rgb(149 157 165 / 60%) 0px 2px 3px 0px;

		.col {
			padding : 25px 0;

			.grow-inner {
				padding          : 0;
				text-align       : center;
				border-right     : 1px solid #080606;
				border-radius    : 0px;
				background-color : #FFFFFF;
				height           : 100%;

				.roll-number {
					display    : flex;
					text-align : center;
					width      : max-content;
					margin     : 0 auto;

					h2 {
						font-size      : 32px;
						color          : #b90017;
						font-weight    : 500;
						letter-spacing : 1px;
						margin-bottom  : 20px;
						width          : max-content;
						float          : left;
					}

					span {
						font-size   : 32px;
						color       : #b90017;
						float       : left;
						font-weight : 500;
					}
				}

				p {
					font-size      : 15px;
					font-weight    : 500;
					letter-spacing : 0.5px;
					text-transform : capitalize;
					margin-bottom  : 0;
				}
			}
		}
	}
}